import { Model } from "@vuex-orm/core";

export default class User extends Model {
  static entity = "users";
  static url = "/user";
  static friendlyName = "User";
  // static primaryKey = "username";

  static state() {
    return {
      graphPhoto: null,
      searchString: null,
      user: {
        environment: null,
        homeAccountId: null,
        idTokenClaims: null,
        localAccountId: null,
        name: null,
        tenantId: null,
        username: null,
        graphPhoto: null,
        graphDetails: null,
        userAccount: null,
      },
    };
  }

  static fields() {
    return {
      id: this.attr(null),
      businessPhones: this.attr(null),
      mail: this.attr(null),
      mobilePhone: this.attr(null),
      officeLocation: this.attr(null),
      surname: this.attr(null),
      userPrincipalName: this.attr(null),
      department: this.attr(null),
      displayName: this.attr(null),
      givenName: this.attr(null),
      environment: this.attr(null),
      homeAccountId: this.attr(null),
      graphPhoto: this.attr(null),
      graphDetails: this.attr(null),
      jobTitle: this.attr(null),
      online: this.attr(false),
      skills: this.attr(null),
    };
  }

  get username() {
    return this.displayName;
  }
  get name() {
    return this.displayName;
  }
}
